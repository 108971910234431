$(function(){
	/* Drag and Drop */
	(function(){
		$(".ui-sortable").sortable({
			handle: ".panel-heading",
			connectWith: ".ui-sortable",
			tolerance: "pointer",
			forcePlaceholderSize: true,
			placeholder: "ui-state-highlight"
		});
	})();
	/* Collapse */
	(function(){
		$(".panel .collapse-panel").on("click", function(){
			$(this).toggleClass("icon-chevron-down icon-chevron-up").closest(".panel").find(".panel-body").slideToggle(400);
		});
	})();
	/* Confirm Remove */
	(function(){
		var confirmModal = $("#modalConfirmPanelClose"),
			panel;
			
		$(".panel .close-panel").on("click", function(){
			panel = $(this).closest(".panel");
			confirmModal.modal();
		});
		
		$(".delete-panel", confirmModal).on("click", function(e){
			e.preventDefault();
			panel.fadeOut(500, function(){
				panel.remove();	
			});
			confirmModal.modal('hide');
		});
	})();
});