$(function(){
	/*
	Menu
	*/
	(function(){
		$(".menu > li > span").on("click", function(){
			if($(this).parent("li").hasClass("active")){
				$(this).parent("li").toggleClass("active").find("ul").slideToggle(200);
			}
			else
			{
				$(".menu li").each(function(){
					$(this).removeClass("active").find("ul").slideUp(200);
				});
				$(this).parent("li").toggleClass("active").find("ul").slideToggle(200);	
			}
		});
	})();
});