/**
 * @class   Aspect.Template
 * @param   {String}    template    The template identifier.
 * @param   {Object}    data        Data to pass into the template.
 * @returns {Object}
 */
Aspect.Template = function (template, data) {

    this.$el        = $(template);
    this.data       = typeof data === 'object' ? data : {};
    this.compiled   = '';

    this.compile();

};

/**
 * @function    compile
 * @description Compiles the template.
 * @returns     {String}
 */
Aspect.Template.prototype.compile = function () {

    var source      = this.$el.html(),
        template    = Handlebars.compile(source);

    this.compiled = template(this.data);

    return this.compiled;

};
