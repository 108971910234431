$(function(){
	/*
	* isOnScreen();
	* Check Element is in viewport
	* http://upshots.org/javascript/jquery-test-if-element-is-in-viewport-visible-on-screen
	*/
	$.fn.isOnScreen = function(){
    
		var win = $(window);
		
		var viewport = {
			top : win.scrollTop(),
			left : win.scrollLeft()
		};
		viewport.right = viewport.left + win.width();
		viewport.bottom = viewport.top + win.height();
		
		var bounds = this.offset();
		bounds.right = bounds.left + this.outerWidth();
		bounds.bottom = bounds.top + this.outerHeight();
		
		return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
	};
});