$(function(){
	/*
	RSVP
	*/
	(function(){
		var currentHiddenMenuRows = $(".menuRow:visible").length;
		var removeMenuChoiceFor = "";
		$(".attendanceOption").on("click", function(){
			if($(this).val() == "No")
			{
				removeMenuChoiceFor = $(this).data("menu-row");
				$("."+removeMenuChoiceFor).hide();
			}
			else
			{
				removeMenuChoiceFor = $(this).data("menu-row");
				$("."+removeMenuChoiceFor).show();
				$(".selectFood, .menuChoices").slideDown(400);	
			}
			
			if($(".menuRow:visible").length === 0){
				$(".selectFood, .menuChoices").slideUp(400);	
			}
		});
	})();
	
	(function(){
		
		var wasChild = "false";
		
		$("select").on("change", function(){
			var $this = $(this);
			
			if($("form.attendanceForm").hasClass("hasChildren")){
				if($this.find(":selected").hasClass("child"))
				{
					wasChild = "true";
					//$this.closest("tr").children('td:nth-child(2)').find(".child").attr('selected', 'selected');
					//$this.closest("tr").children().not('td:nth-child(2)').find("select").children().hide();
					$this.closest("tr").children().find("select").children(".child").attr('selected', 'selected');
					$this.closest("tr").children('td:nth-child(5)').hide();
				}
				else
				{
					if(wasChild == "true")
					{
						//$this.closest("tr").children().not('td:nth-child(2)').find("select").children().show();
						$this.closest("tr").children().find("select").children(".child").removeAttr('selected');
						//$this.closest("tr").children().find("select").each(function(){
							//$(this).children(".adult").first().attr('selected', 'selected');
						//});
						$this.closest("tr").children('td:nth-child(5)').show();
						wasChild = "false";
					}
				}
			}
		});
	})();
});