$(function(){
	/*
	Global
	*/
	(function(){
		$("html").niceScroll({
			cursorcolor: "#666",
			cursoropacitymin: 0.8,
			cursoropacitymax: 0.8,
			cursorwidth: "10px",
			cursorborder: "0",
			cursorborderradius: "3px",
			zindex: 999
		});
		
		$(window).on("load", function(){
			//Initiate WOW.js
			new WOW({offset: 100, mobile: false}).init();
		});
	})();
});