$(function(){
	/*
	Mobile
	*/
	(function(){
		var CounterUpRunner = false;
		$(window).on("scroll", function(){
			if($(".counter").isOnScreen() && !CounterUpRunner){
				CounterUpRunner = true;
				$(".counter").counterUp({
					delay: 10,
					time: 2000	
				});
			}
		});
	})();
});