$(function(){
	/*
	Map
	*/
	(function(){
		var gmap	= $('.map'),
			markers	= gmap.data('markers');

		if (gmap.length) {

			// Get the latitude and longitude
			var latitude	= 51.507351;
			var longitude	= -0.127758;

			// Instantiate the geocoder
			var geocoder = new google.maps.Geocoder();

			// Set the default latitude and longitude
			var mapLatLng = new google.maps.LatLng(latitude, longitude);

			// Initialize the map
			var map = new google.maps.Map(gmap[0], {
				zoom: 13,
				center:	mapLatLng,
				scrollwheel: false
			});

			// Setup the map marker in the centre
			if (typeof markers === 'undefined') {
				var marker = new google.maps.Marker({
					position:	mapLatLng,
					map:		map
				});
			}

		}
	})();
});