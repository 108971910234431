$(function(){
	/*
	Mobile
	*/
	(function(){
		$(".mobile-menu-handle").on("click", function(){
			$(this).toggleClass("expanded");
			$(".menu").slideToggle(400);
		});
	})();
});